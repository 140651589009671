import './StartApp.css';
import {store} from "./redux/store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import RouterPageView from "./router/RouterPageView";

function StartApp() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <RouterPageView/>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default StartApp;
