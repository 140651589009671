import instance from "../config/AxiosConfig";
import requestPath from "./requestPath";
import axios from "axios";

const APIService = {
  user: {
    requestSendMailCode: async (email) => {
      const response = await instance.post(requestPath.user.requestSendMailCode, {email: email});
      return response;
    },

    requestLogin: async (params = {}) => {
      const response = await instance.post(requestPath.user.requestLogin, params);
      return response;
    }
  },
  category: {
    getTrainingCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.trainingCategory, params);
      return response;
    },
    getProductCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.productCategory, params);
      return response;
    },
    getReportCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.reportCategory, params);
      return response;
    },
    getTrainingFistCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminTrainingFirstCategory, params);
      return response;
    },
    getTrainingSecondCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminTrainingSecondCategory, {params: params});
      return response;
    },
    getTrainingThirdCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminTrainingThirdCategory, {params: params});
      return response;
    },
    getTrainingFourthCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminTrainingFourthCategory, {params: params});
      return response;
    },
    insertFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertTrainingFirstCategory, params);
      return response;
    },
    insertSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertTrainingSecondCategory, params);
      return response;
    },
    insertThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertTrainingThirdCategory, params);
      return response;
    },
    insertFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertTrainingFourthCategory, params);
      return response;
    },
    deleteFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteTrainingFirstCategory, params);
      return response;
    },
    deleteSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteTrainingSecondCategory, params);
      return response;
    },
    deleteThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteTrainingThirdCategory, params);
      return response;
    },
    deleteFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteTrainingFourthCategory, params);
      return response;
    },
    updateFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateTrainingFirstCategory, params);
      return response;
    },
    updateSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateTrainingSecondCategory, params);
      return response;
    },
    updateThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateTrainingThirdCategory, params);
      return response;
    },
    updateFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateTrainingFourthCategory, params);
      return response;
    },
    moveFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveTrainingFirstCategory, params);
      return response;
    },
    moveSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveTrainingSecondCategory, params);
      return response;
    },
    moveThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveTrainingThirdCategory, params);
      return response;
    },
    moveFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveTrainingFourthCategory, params);
      return response;
    },
    getProductFistCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminProductFirstCategory, params);
      return response;
    },
    getProductSecondCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminProductSecondCategory, {params: params});
      return response;
    },
    getProductThirdCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminProductThirdCategory, {params: params});
      return response;
    },
    getProductFourthCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminProductFourthCategory, {params: params});
      return response;
    },
    insertProductFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertProductFirstCategory, params);
      return response;
    },
    insertProductSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertProductSecondCategory, params);
      return response;
    },
    insertProductThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertProductThirdCategory, params);
      return response;
    },
    insertProductFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertProductFourthCategory, params);
      return response;
    },
    deleteProductFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteProductFirstCategory, params);
      return response;
    },
    deleteProductSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteProductSecondCategory, params);
      return response;
    },
    deleteProductThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteProductThirdCategory, params);
      return response;
    },
    deleteProductFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteProductFourthCategory, params);
      return response;
    },
    updateProductFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateProductFirstCategory, params);
      return response;
    },
    updateProductSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateProductSecondCategory, params);
      return response;
    },
    updateProductThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateProductThirdCategory, params);
      return response;
    },
    updateProductFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateProductFourthCategory, params);
      return response;
    },
    moveProductFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveProductFirstCategory, params);
      return response;
    },
    moveProductSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveProductSecondCategory, params);
      return response;
    },
    moveProductThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveProductThirdCategory, params);
      return response;
    },
    moveProductFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveProductFourthCategory, params);
      return response;
    },

    // Report Category API //
    getReportFistCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminReportFirstCategory, params);
      return response;
    },
    getReportSecondCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminReportSecondCategory, {params: params});
      return response;
    },
    getReportThirdCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminReportThirdCategory, {params: params});
      return response;
    },
    getReportFourthCategory: async (params = {}) => {
      const response = await instance.get(requestPath.category.adminReportFourthCategory, {params: params});
      return response;
    },
    insertReportFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertReportFirstCategory, params);
      return response;
    },
    insertReportSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertReportSecondCategory, params);
      return response;
    },
    insertReportThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertReportThirdCategory, params);
      return response;
    },
    insertReportFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminInsertReportFourthCategory, params);
      return response;
    },
    deleteReportFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteReportFirstCategory, params);
      return response;
    },
    deleteReportSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteReportSecondCategory, params);
      return response;
    },
    deleteReportThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteReportThirdCategory, params);
      return response;
    },
    deleteReportFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminDeleteReportFourthCategory, params);
      return response;
    },
    updateReportFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateReportFirstCategory, params);
      return response;
    },
    updateReportSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateReportSecondCategory, params);
      return response;
    },
    updateReportThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateReportThirdCategory, params);
      return response;
    },
    updateReportFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminUpdateReportFourthCategory, params);
      return response;
    },
    moveReportFirstCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveReportFirstCategory, params);
      return response;
    },
    moveReportSecondCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveReportSecondCategory, params);
      return response;
    },
    moveReportThirdCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveReportThirdCategory, params);
      return response;
    },
    moveReportFourthCategory: async (params = {}) => {
      const response = await instance.post(requestPath.category.adminMoveReportFourthCategory, params);
      return response;
    }
  },
  content: {
    getTrainingContentList: async (params = {}) => {
      const response = await instance.post(requestPath.content.trainingContentList, params);
      return response;
    },
    getProductContentList: async (params = {}) => {
      const response = await instance.post(requestPath.content.productContentList, params);
      return response;
    },
    getReportContentList: async (params = {}) => {
      const response = await instance.post(requestPath.content.reportContentList,  params);
      return response;
    },
    getTrainingTotalList: async (params = {}) => {
      const response = await instance.get(requestPath.content.adminTrainingTotalList, {params: params});
      return response;
    },
    getProductTotalList: async (params = {}) => {
      const response = await instance.get(requestPath.content.adminProductTotalList, {params: params});
      return response;
    },
    getReportTotalList: async (params = {}) => {
      const response = await instance.get(requestPath.content.adminReportTotalList, {params: params});
      return response;
    }
  },
  file: {
    uploadFile: async (params = {}) => {
      return await instance.post(requestPath.file.uploadFile, params);
    }
  },
  notice: {
    getNoticeList: async (params = {}) => {
      const response = await instance.post(requestPath.notice.noticeList, params);
      return response;
    },
    insertNotice: async (params = {}) => {
      const response = await instance.post(requestPath.notice.insertNotice, params);
      return response;
    },
    getNoticeDetail: async (params) => {
      const response = await instance.get(requestPath.notice.noticeDetail + `${params}`);
      return response;
    },
    updateNotice: async (params = {}) => {
      const response = await instance.post(requestPath.notice.noticeUpdate, params);
      return response;
    },
    deleteNotice: async (params = {}) => {
      const response = await instance.post(requestPath.notice.noticeDelete, params);
      return response;
    }
  },
  qna: {
    getQnaList: async (params = {}) => {
      const response = await instance.post(requestPath.qna.qnaList, params);
      return response;
    },
    getQnaDetail: async (params) => {
      const response = await instance.get(requestPath.qna.qnaDetail + `${params}`);
      return response;
    },
    getQnaAnswerDetail: async (params) => {
      const response = await instance.get(requestPath.qna.qnaAnswerDetail + `${params}`);
      return response;
    },
    insertQna: async (params = {}) => {
      const response = await instance.post(requestPath.qna.qnaInsert, params);
      return response;
    },
    updateQna: async (params = {}) => {
      const response = await instance.post(requestPath.qna.qnaUpdate, params);
      return response;
    },
    deleteQna: async (params = {}) => {
      const response = await instance.post(requestPath.qna.qnaDelete, params);
      return response;
    },
    insertAnswer: async (params = {}) => {
      const response = await instance.post(requestPath.qna.qnaAnswerInsert, params);
      return response;
    },
    updateAnswer: async (params = {}) => {
      const response = await instance.post(requestPath.qna.qnaAnswerUpdate, params);
      return response;
    },
    deleteAnswer: async (params = {}) => {
      const response = await instance.post(requestPath.qna.qnaAnswerDelete, params);
      return response;
    }
  },
  dashboard: {
    getDashboardInfo: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.getDashboardInfo, params);
      return response;
    },
    getContentInfo: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.getContentInfo, params);
      return response;
    },    
    getProductContentInfo: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.getProductContentInfo, params);
      return response;
    },  
    getReportInfo: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.getReportInfo, params);
      return response;
    },    
    insertVisitor: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.insertVisitor, params);
      return response;
    },
    insertDownloadCount: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.insertDownloadCount, params);
      return response;
    },
    insertTrainingViewCount: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.insertTrainingViewCount, params);
      return response;
    },
    insertProductViewCount: async (params = {}) => {
      const response = await instance.post(requestPath.dashboard.insertProductViewCount, params);
      return response;
    },
    deleteContentTraining : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.deleteContentTraining, params);
      return response;
    },
    insertContentTraining : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.insertContentTraining, params);
      return response;
    },
    updateContentTraining : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.updateContentTraining, params);
      return response;
    },
    insertContentProduct : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.insertContentProduct, params);
      return response;
    },
    updateContentProduct : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.updateContentProduct, params);
      return response;
    },   
    deleteContentProduct : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.deleteContentProduct, params);
      return response;
    },    
    deleteContentReport : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.deleteContentReport, params);
      return response;
    },
    insertContentReport : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.insertContentReport, params);
      return response;
    },
    updateContentReport : async  (params ={}) =>{
      const response = await instance.post(requestPath.dashboard.updateContentReport, params);
      return response;
    },    
    downloadExcel : async  (params = {}) =>{
      const response = await instance.post(requestPath.dashboard.downloadExcel, params);
      return response;
    }
  },
  utils: {
    getGeoInfo: async () => {
      const response = await axios.get("https://ipapi.co/json/");
      return response;
    }
  }
}

export default APIService;
