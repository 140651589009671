import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise";
import {rootReducer} from "./reducers/MainReducer.js";
import {logger} from "redux-logger/src";


// devtools for debugging in dev environment.
const devTools =
  process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
    : (a) => a;

export const store = createStore(
  rootReducer,

  process.env.NODE_ENV !== 'production' ? compose(applyMiddleware(thunk, promiseMiddleware, logger), devTools) : compose(applyMiddleware(thunk, promiseMiddleware), devTools)
);