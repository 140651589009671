const requestPath = {
  user: {
    requestSendMailCode: '/Login/sendEmail', //사용자 로그인 인증코드 메일발송 API
    requestLogin: '/Login/login' // 사용자 로그인 API
  },
  category: {
    trainingCategory: '/training/getCategory', // Training Materials 카테고리 목록조회
    productCategory: '/product/getCategory', // Product Decomposition 카테고리 목록조회
    reportCategory: '/report/getCategory', // Rport 카테고리 목록조회
    adminTrainingFirstCategory: '/training/category/first',
    adminTrainingSecondCategory: '/training/category/second',
    adminTrainingThirdCategory: '/training/category/third',
    adminTrainingFourthCategory:'/training/category/fourth',
    adminInsertTrainingFirstCategory: '/training/categorySave/first',
    adminInsertTrainingSecondCategory: '/training/categorySave/second',
    adminInsertTrainingThirdCategory: '/training/categorySave/third',
    adminInsertTrainingFourthCategory:'/training/categorySave/fourth',
    adminDeleteTrainingFirstCategory: '/training/categoryDelete/first',
    adminDeleteTrainingSecondCategory: '/training/categoryDelete/second',
    adminDeleteTrainingThirdCategory: '/training/categoryDelete/third',
    adminDeleteTrainingFourthCategory: '/training/categoryDelete/fourth',
    adminUpdateTrainingFirstCategory: '/training/categoryModify/first',
    adminUpdateTrainingSecondCategory: '/training/categoryModify/second',
    adminUpdateTrainingThirdCategory: '/training/categoryModify/third',
    adminUpdateTrainingFourthCategory: '/training/categoryModify/fourth',
    adminMoveTrainingFirstCategory: '/training/categoryMove/first',
    adminMoveTrainingSecondCategory: '/training/categoryMove/second',
    adminMoveTrainingThirdCategory: '/training/categoryMove/third',
    adminMoveTrainingFourthCategory:'/training/categoryMove/fourth',
  
    //Product
    adminProductFirstCategory: '/product/category/first',
    adminProductSecondCategory: '/product/category/second',
    adminProductThirdCategory: '/product/category/third',
    adminProductFourthCategory:'/product/category/fourth',
    adminInsertProductFirstCategory: '/product/categorySave/first',
    adminInsertProductSecondCategory: '/product/categorySave/second',
    adminInsertProductThirdCategory: '/product/categorySave/third',
    adminInsertProductFourthCategory:'/product/categorySave/fourth',
    adminDeleteProductFirstCategory: '/product/categoryDelete/first',
    adminDeleteProductSecondCategory: '/product/categoryDelete/second',
    adminDeleteProductThirdCategory: '/product/categoryDelete/third',
    adminDeleteProductFourthCategory: '/product/categoryDelete/fourth',
    adminUpdateProductFirstCategory: '/product/categoryModify/first',
    adminUpdateProductSecondCategory: '/product/categoryModify/second',
    adminUpdateProductThirdCategory: '/product/categoryModify/third',
    adminUpdateProductFourthCategory: '/product/categoryModify/fourth',
    adminMoveProductFirstCategory: '/product/categoryMove/first',
    adminMoveProductSecondCategory: '/product/categoryMove/second',
    adminMoveProductThirdCategory: '/product/categoryMove/third',
    adminMoveProductFourthCategory:'/product/categoryMove/fourth',

    //Report
    adminReportFirstCategory: '/report/category/first',
    adminReportSecondCategory: '/report/category/second',
    adminReportThirdCategory: '/report/category/third',
    adminReportFourthCategory:'/report/category/fourth',
    adminInsertReportFirstCategory: '/report/categorySave/first',
    adminInsertReportSecondCategory: '/report/categorySave/second',
    adminInsertReportThirdCategory: '/report/categorySave/third',
    adminInsertReportFourthCategory:'/report/categorySave/fourth',
    adminDeleteReportFirstCategory: '/report/categoryDelete/first',
    adminDeleteReportSecondCategory: '/report/categoryDelete/second',
    adminDeleteReportThirdCategory: '/report/categoryDelete/third',
    adminDeleteReportFourthCategory: '/report/categoryDelete/fourth',
    adminUpdateReportFirstCategory: '/report/categoryModify/first',
    adminUpdateReportSecondCategory: '/report/categoryModify/second',
    adminUpdateReportThirdCategory: '/report/categoryModify/third',
    adminUpdateReportFourthCategory:'/report/categoryModify/fourth',
    adminMoveReportFirstCategory: '/report/categoryMove/first',
    adminMoveReportSecondCategory: '/report/categoryMove/second',
    adminMoveReportThirdCategory: '/report/categoryMove/third',
    adminMoveReportFourthCategory:'/report/categoryMove/fourth'
  },
  content: {
    trainingContentList: '/training/contentList',
    productContentList: '/product/contentList',
    reportContentList: '/report/contentList',
    adminTrainingTotalList: '/training/getTotalList',
    adminProductTotalList: '/product/getTotalList',
    adminReportTotalList: '/report/getTotalList'
  },
  file: {
    uploadFile: '/notice/upload/img'
  },
  notice: {
    noticeList: '/notice/Board/getList',
    noticeUploadImage: '/notice/upload/img',
    insertNotice: '/notice/Board/savePost',
    noticeDetail: '/notice/Board/getPost/',
    noticeUpdate: '/notice/Board/modify',
    noticeDelete: '/notice/Board/delete'
  },
  qna: {
    qnaList: '/notice/Qna/getList',
    qnaInsert: '/notice/Qna/savePost',
    qnaUpdate: '/notice/Qna/modify',
    qnaDelete: '/notice/Qna/delete',
    qnaDetail: '/notice/Qna/getQna/',
    qnaAnswerInsert: '/notice/Answer/savePost',
    qnaAnswerUpdate: '/notice/Answer/modify',
    qnaAnswerDelete: '/notice/Answer/delete',
    qnaAnswerDetail: '/notice/Qna/getAnswer/'
  },
  dashboard: {
    getDashboardInfo: '/dashboard/getInfo',
    getContentInfo: '/training/getContent',
    getProductContentInfo: '/product/getContent',
    getReportInfo: '/report/getContent',
    insertVisitor: '/visitor/save',
    insertDownloadCount: '/training/downloadcount',
    insertTrainingViewCount: '/training/viewcount',
    insertProductViewCount: '/product/viewcount',
    deleteContentTraining: '/training/contentListDelete',
    insertContentTraining: '/training/contentSave',
    updateContentTraining: '/training/contentModify',
    insertContentProduct: '/product/contentSave',
    updateContentProduct: '/product/contentModify',
    deleteContentProduct: '/product/contentListDelete',
    deleteContentReport: '/report/contentListDelete',
    insertContentReport: '/report/contentSave',
    updateContentReport: '/report/contentModify',
    downloadExcel: '/dashboard/excel/download',
  }
}

export default requestPath;