import {CookieUtils} from "./StorageUtils";
import axios from "axios";
import APIService from "../api/APIService";

const utils = {

  /**
   * Null 체크
   * @param target
   * @returns {boolean} null -> true
   */
  isNull: (target) => {
    let result = false;
    try {
      if (target === undefined || target === 'undefined' || target == null || target === 'null' || (target === 'NaN') | (target === '') || target.length <= 0) {
        result = true;
      }
    } catch (e) {
      result = false;
    }
    return result;
  },

  isLogin() {
    const token = CookieUtils.get('tokenInfo');
    if (utils.isNull(token)) {
      return false;
    }

    const currentTime = new Date().getTime();
    return token.accessTokenExpires > currentTime;
  },

  getUserInfo() {
    const token = CookieUtils.get('tokenInfo');
    if (utils.isNull(token)) {
      return false;
    }

    return token;
  },

  getExtension(path) {
    const extension = String(path).split(".")[String(path).split(".").length - 1];
    if (extension) {
      return extension;
    }
    return null;
  },

  scrollDisable: function () {
    document.querySelector('body').classList.add('scrollStop');
  },

  scrollEnable: function () {
    document.querySelector('body').classList.remove('scrollStop');
  },
  fileDownload: function (path, contentNo) {
    const fileName = decodeURIComponent(String(path).split('/')[String(path).split('/').length - 1]);
    axios.get(path, {responseType: 'blob'}).then(async (response) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = fileName;
      a.click();
      await APIService.dashboard.insertDownloadCount({contentNo: contentNo});
    });
  },

  fileDownloadInBoard: function (path,name) {
    const fileName = decodeURIComponent(String(name).split('/')[String(name).split('/').length - 1]);
    axios.get(path, {responseType: 'blob'}).then(async (response) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = fileName;
      a.click();
    });
  },

  /**
   * date format 변경
   * @param date(yyyy-mm-dd hh:mm:ss)
   * @returns {string} yyyy.mm.dd
   */
  getDotDate(date = null) {
    if (!utils.isNull(date)) {
      let year = date.substr(0, 4);
      let month = date.substr(5, 2);
      let day = date.substr(8, 2);

      let data = [];
      data.push(year, month, day);
      return data.join('.');
    }
  },

  /**
   * date format 변경
   * @param date(yyyy-mm-dd hh:mm:ss)
   * @returns {string} yyyy-mm-dd
   */
  getFormatDate(date = null) {
    if (!utils.isNull(date)) {
      let year = String(date.getFullYear());
      let month = String((date.getMonth() + 1))
      let day = String(date.getDate())

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
  },
  addComma(num) {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
  },
  isPC(){
    return document.body.clientWidth >= 1194
  }
}

export default utils;
