import React, {lazy, Suspense, useEffect} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import {pagePath} from "./RouterPath";
import AuthRoute from "./AuthRoute";

const Header = lazy(() => import("../views/components/header/Header"));
const Footer = lazy(() => import("../views/components/footer/Footer"));
const MainContainer = lazy(() => import("../views/containers/MainContainer/MainContainer"));
const LoginContainer = lazy(() => import("../views/containers/LoginContainer/LoginContainer"));
const NoticeContainer = lazy(() => import("../views/containers/BoardContainer/BoardContainer"));
const NoticeWriteContainer = lazy(() => import("../views/containers/BoardContainer/BoardWriteContainer"))
const NoticeDetailContainer = lazy(() => import("../views/containers/BoardContainer/BoardDetailContainer"))
const TrainingMaterialsContainer = lazy(() => import("../views/containers/TrainingMaterialsContainer/TrainingMaterialsContainer"));
const ProductDecompositionContainer = lazy(() => import("../views/containers/ProductDecompositionContainer/ProductDecompositionContainer"));
const PartyReportContainer = lazy(() => import("../views/containers/PartyReportContainer/PartyReportContainer"));
const DashboardHomeContainer = lazy(() => import("../views/containers/DashboardContainer/DashboardHomeContainer/DashboardHomeContainer"));


const RouterPageView = (props) => {
    /**
     * 페이지 이동 할때마다 스크롤 상단으로 이동
     **/
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='page'>

            <Suspense fallback={(<div></div>)}>
                <Header/>
                <Switch>
                    <Route exact path={pagePath.login} component={LoginContainer}/>
                    <AuthRoute exact path={pagePath.root} component={MainContainer}/>
                    <AuthRoute exact path={pagePath.board} component={NoticeContainer}/>
                    <AuthRoute exact path={pagePath.boardWrite} component={NoticeWriteContainer}/>
                    <AuthRoute exact path={pagePath.boardDetail} component={NoticeDetailContainer}/>
                    <AuthRoute exact path={pagePath.training} component={TrainingMaterialsContainer}/>
                    <AuthRoute exact path={pagePath.product} component={ProductDecompositionContainer}/>
                    <AuthRoute exact path={pagePath.report} component={PartyReportContainer}/>
                    <AuthRoute exact path={pagePath.dashboard.dashboard} component={DashboardHomeContainer}/>
                </Switch>
                <Footer/>
            </Suspense>
        </div>
    )
}
export default RouterPageView;