import {combineReducers} from "redux";
import {CommonReducer} from "./CommonReducer";
import {ContentsReducer} from "./ContentsReducer";
import {DashboardReducer} from "./DashboardReducer";
import {BoardReducer} from "./BoardReducer";

export const rootReducer = combineReducers({
  CommonReducer,
  ContentsReducer,
  DashboardReducer,
  BoardReducer
});
