import utils from "../../utils/utils";
import {SET_BOARD_TAB_TYPE} from "../actions/actionTypes";
import produce from "immer";

const init = {};
export const BoardReducer = (state = init, action) => {
    switch (action.type) {
        case SET_BOARD_TAB_TYPE :
            return produce(state, draft => {
                draft.boardType = null;
                if (!utils.isNull(action.payload)) {
                    draft.boardType = action.payload;
                }
            })

        default:
            return state;
    }
}