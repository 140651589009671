import requestPath from "../api/requestPath";

const axios = require("axios");
const axiosConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
}

const instance = axios.create(axiosConfig)

instance.interceptors.request.use((config) => {
    if (config.url === requestPath.file.uploadFile) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }

    if (config.url === requestPath.dashboard.downloadExcel) {
      config.responseType = "blob";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  })

export default instance;