import produce from "immer";
import utils from "../../utils/utils";
import {SET_DASHBOARD_TAB_AUCTION_TYPE, SET_FILE_DELETE_CHECK_TYPE,SET_DASHBOARD_CATEGORY_SELECT_TYPE,SET_DASHBOARD_CONTENTS_SELECT_TYPE,SET_DASHBOARD_CONTENTS_SELECT_TYPE2} from "../actions/actionTypes";
import {SET_FILE_DELETE_CHECK} from "../actions/DashboardActions";

const init = {};
export const DashboardReducer = (state = init, action) => {
  switch (action.type) {
    case SET_DASHBOARD_TAB_AUCTION_TYPE :
      return produce(state, draft => {
        draft.dashboardInfo = {};
        if (!utils.isNull(action.payload)) {
          draft.dashboardInfo = action.payload;
        }
      })
      
    case SET_FILE_DELETE_CHECK_TYPE :
      return produce(state, draft => {
        if (utils.isNull(draft.checkeds)){
          draft.checkeds = [];
        }
        if (!utils.isNull(action.payload)) {
          draft.checkeds.push(action.payload)
        }
      })

      case SET_DASHBOARD_CATEGORY_SELECT_TYPE:
        return produce(state, draft => {
          draft.dashboardCategorySelectItem = {};
          if (!utils.isNull(action.payload)) {
            draft.dashboardCategorySelectItem = action.payload;
          }
        })

    case SET_DASHBOARD_CONTENTS_SELECT_TYPE:
      return produce(state, draft => {
        draft.dashboardContetsSelectItem = {};
        if (!utils.isNull(action.payload)) {
          draft.dashboardContetsSelectItem = action.payload;
        }
      })

      case SET_DASHBOARD_CONTENTS_SELECT_TYPE2:
        return produce(state, draft => {
          draft.dashboardContetsSelectItem2 = {};
          if (!utils.isNull(action.payload)) {
            draft.dashboardContetsSelectItem2 = action.payload;
          }
        })

    default:
      return state;
  }
}