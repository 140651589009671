import produce from "immer";
import utils from "../../utils/utils";
import {GET_CATEGORY_SELECT_ACTION_TYPE, GET_PRODUCT_CATEGORY_SELECT_ACTION_TYPE,GET_REPORT_CATEGORY_SELECT_ACTION_TYPE} from "../actions/actionTypes";

const init = {};
export const ContentsReducer = (state = init, action) => {
  switch (action.type) {
    case GET_CATEGORY_SELECT_ACTION_TYPE :
      return produce(state, draft => {
        draft.list = [];
        if (!utils.isNull(action.payload)) {
          draft.list = action.payload;
        }
      })

    case GET_PRODUCT_CATEGORY_SELECT_ACTION_TYPE:
      return produce(state, draft => {
        draft.item = {};
        if (!utils.isNull(action.payload)) {
          draft.item = action.payload;
        }
      })

      case GET_REPORT_CATEGORY_SELECT_ACTION_TYPE :
        return produce(state, draft => {
          draft.list = [];
          if (!utils.isNull(action.payload)) {
            draft.list = action.payload;
          }
        })

    default:
      return state;
  }
}