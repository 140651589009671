import {
  SET_DASHBOARD_TAB_AUCTION_TYPE,
  SET_FILE_DELETE_CHECK_TYPE,
  SET_DASHBOARD_CATEGORY_SELECT_TYPE,
  SET_DASHBOARD_CONTENTS_SELECT_TYPE,
  SET_DASHBOARD_CONTENTS_SELECT_TYPE2
} from "./actionTypes";

export function SET_DASHBOARD_TAB_AUCTION(params = {}) {
  return {
    type: SET_DASHBOARD_TAB_AUCTION_TYPE,
    payload: params,
  };
}


export function SET_FILE_DELETE_CHECK(params = {}) {
  return {
    type: SET_FILE_DELETE_CHECK_TYPE,
    payload: params,
  };
}

export function SET_DASHBOARD_CATEGORY_SELECT(params=null) {
  return {
    type:SET_DASHBOARD_CATEGORY_SELECT_TYPE,
    payload:params,
  }
}
export function SET_DASHBOARD_CONTENTS_SELECT(params=null) {
  return {
    type:SET_DASHBOARD_CONTENTS_SELECT_TYPE,
    payload:params,
  }
}
export function SET_DASHBOARD_CONTENTS_SELECT2(params=null) {
  return {
    type:SET_DASHBOARD_CONTENTS_SELECT_TYPE2,
    payload:params,
  }
}
