export const GET_CATEGORY_SELECT_ACTION_TYPE = "GET_CATEGORY_SELECT";
export const GET_PRODUCT_CATEGORY_SELECT_ACTION_TYPE = "GET_PRODUCT_CATEGORY_SELECT";
export const GET_REPORT_CATEGORY_SELECT_ACTION_TYPE = "GET_REPORT_CATEGORY_SELECT";

// dashboard
export const SET_DASHBOARD_TAB_AUCTION_TYPE = "SET_DASHBOARD_TAB";

// board
export const SET_BOARD_TAB_TYPE = "SET_BOARD_TAB_TYPE";

// dashboard check
export const SET_FILE_DELETE_CHECK_TYPE = "SET_FILE_DELETE_CHECK";

// dashboard contents select
export const SET_DASHBOARD_CATEGORY_SELECT_TYPE = "SET_DASHBOARD_CATEGORY_SELECT_TYPE";

// dashboard contents select
export const SET_DASHBOARD_CONTENTS_SELECT_TYPE = "SET_DASHBOARD_CONTENTS_SELECT_TYPE";

// dashboard contents select
export const SET_DASHBOARD_CONTENTS_SELECT_TYPE2 = "SET_DASHBOARD_CONTENTS_SELECT_TYPE2";
