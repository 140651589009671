import {Redirect, Route} from "react-router-dom";
import utils from "../utils/utils";
import {pagePath} from "./RouterPath";
import AlertUtils from "../utils/SwalAlertUtils";
import {CookieUtils} from "../utils/StorageUtils";

const AuthRoute = ({component: Component, ...rest}) => {
  const onlyAdminPath = [pagePath.dashboard.dashboard]

  utils.scrollEnable();
  if (onlyAdminPath.indexOf({...rest}.computedMatch.path) > -1) {
    const tokenInfo = CookieUtils.get("tokenInfo");
    if (tokenInfo != null && String(tokenInfo.accessUser).toUpperCase() === "USER") {
      AlertUtils.alertError("access denied for this page. \n You have moved to the main page.");
      return (
        <Redirect to={pagePath.root}/>
      )
    }
  }


  return (
    <Route {...rest} render={props => (
      utils.isLogin() ? <Component {...props} /> : <Redirect to={pagePath.login}/>
    )}/>
  )


}
export default AuthRoute;