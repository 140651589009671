import {Cookies} from "react-cookie";
import utils from "./utils";

const cookie = new Cookies();

export const CookieUtils = {
  set: (key, value, options = {}) => {
    if (!utils.isNull(key) && !utils.isNull(value)) {
      cookie.set(key, value, options);
    }

  },

  get: (key) => {
    if (!utils.isNull(key)) {
      return cookie.get(key)
    }

  },

  remove: (key, options = {}) => {
    if (!utils.isNull(key)) {
      cookie.remove(key, options)
    }

  },

}

export const LocalStorageUtils = {
  get(key) {
    if (!utils.isNull(key)) {
      return window.localStorage.getItem(key);
    }
    return null;
  },
  set(key, value) {
    if (!utils.isNull(key) && !utils.isNull(value)) {
      window.localStorage.setItem(key, value);
    }
  },
  remove(key) {
    if (!utils.isNull(key)) {
      window.localStorage.removeItem(key);
    }
  },
  clear() {
    window.localStorage.clear();
  },
};

export const SessionUtils = {
  get(key) {
    if (!utils.isNull(key)) {
      return window.sessionStorage.getItem(key);
    }
    return null;
  },
  set(key, value) {
    if (!utils.isNull(key) && !utils.isNull(value)) {
      window.sessionStorage.setItem(key, value);
    }
  },
  remove(key) {
    if (!utils.isNull(key)) {
      window.sessionStorage.removeItem(key);
    }

  },
  clear() {
    window.sessionStorage.clear();
  },
};